import React from "react"
import { Wifi } from "react-feather"

const dummyData = [
  {title: 'Lake Access',},
  {title: 'Wifi'},
  {title: 'Private hot tub'},
  {title: 'TV'},
  {title: 'Kitchen'},
  {title: 'Free parking on premises'},
  {title: 'Pets Allowed'},
  {title: 'Security cameras on property'},
]

const Amenities = ({data}) => {
  console.log(data.nodes)
  const amenities = data.nodes.map(({url, meta: {title} = {}}) => ({url, title}));
  return (
    <div className="grid grid-rows-4 lg:grid-cols-2 gap-8 px-8 my-12 sm:grid-cols-1">
      {(amenities.length ? amenities : dummyData).map(({url, title}) => <div key={title} className="flex gap-4 items-center">
        {url ? 
          <img
            className="h-5 w-5"
            src={url}
            alt="placeholder"
            placeholder="blurred"
          /> : 
            <Wifi />
        }
       <p className="text-2xl">{title}</p>
       </div>)}
    </div>
  )
}

export default Amenities
