import React from "react"

const Banner = ({ data }) => {
  const {url} = data.nodes[0] ?? {
    url: 'https://media.kubric.io/api/assetlib/aafbc620-6f4d-4548-aaa7-a98cc438fcfc.jpg'
  } 
  return (
    <div className="banner-wrap full-bleed bg-gray-200 overflow-hidden">
        {/* <StaticImage
          className="w-full h-full object-cover"
          src={url}
          alt="banner"
          placeholder="blurred"
        /> */}
        <img
          className="w-full h-full object-cover"
          src={url}
          alt="banner"
        />
    </div>
  )
}

export default Banner
