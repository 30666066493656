import React from "react"
import { Award, Home, MapPin, Star } from "react-feather"

const dummyFeatures = [{
  title: 'Enhanced Clean',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: Star,
  position: 1
},
{
  title: 'Great Location',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: MapPin,
  position: 2
},
{
  title: 'Pets Allowed',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: Award,
  position: 3
},
{
  title: 'Entire Home',
  description: 'This host has committed to Airbnb\'s 5-step enhanced cleaning process.',
  Icon: Home,
  position: 4
}
]


const GridA = ({ data }) => {
  console.log("GRID A ", data)
  const features = data.nodes.map(({url, meta: {heading, description, position} = {}}) => ({
    url, title: heading, description, position
  }))
  const dataToRender = features.length ? features : dummyFeatures;
  return (
    <div className="grid-a-wrap grid sm:grid-cols-2 grid-cols-1 gap-12 sm:grid-rows-4 md:grid-rows-2 py-12 my-12 px-12">
      {dataToRender.sort(({position}, {positionB}) => position - positionB).map((card, i) => {
        let {title, description, url, Icon} = card;
        return (
          <div key={i} className="grid-img-wrap small-grid-card flex gap-4 mb-8">
            {Icon ? <Icon className="mt-0.5" /> : <img
              className="h-5 w-5 box-content py-1"
              src={url}
              alt="placeholder"
              placeholder="blurred"
            />}
            <span>
              <p className="font-bold text-lg">{title}</p>
              <p className="text-gray-400">{description}</p>
            </span>
          </div>)
        })
      }
    </div>
  )
}

export default GridA
