import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Globe, User } from "react-feather"
import { StaticImage } from "gatsby-plugin-image"
import { useAuth0 } from "@auth0/auth0-react"

const Header = ({ siteTitle }) => {
  const { isAuthenticated, user, logout, loginWithRedirect } = useAuth0()
  return (
    <div className="full-bleed layouted bg-white text-black">
      <header className="flex items-center justify-between h-24 px-4 md:px-0">
        <div className="branding flex items-center">
          <StaticImage
            className="w-12 mr-4"
            src="https://media.kubric.io/api/assetlib/06e60a46-35a6-4234-bb8b-961d4a615b39.png"
            alt="garnet logo"
          />
          <Link to="/">
            <h1 className="text-2xl">{siteTitle}</h1>
          </Link>
        </div>
        <nav className="flex">
          <Link className="mx-2 relative" to="/">
            <Globe />
            <span className="font-bold text-xs absolute -top-2.5 -right-2.5">
              EN
            </span>
          </Link>
          <Link className="mx-2" to="/">
            <User />
          </Link>
          {!isAuthenticated ? (
            <a className="mx-2 login__button" onClick={loginWithRedirect}>
              Login/Signup
            </a>
          ) : (
            <div>
              <span id="hello">Hello, {user.name}!</span>{" "}
              <button
                className="py-2 px-4 rounded-lg text-white logout__button"
                id="logout"
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                logout
              </button>
            </div>
          )}
        </nav>
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
