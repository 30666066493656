import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Search, Star } from "react-feather"

const RatingMeter = ({text, ratings}) => {
  return <div className="flex justify-between">
    <p>{text}</p>
    <div className="flex items-center gap-2">
      <div className="relative w-56 h-2 rounded-lg overflow-hidden">
        <span style={{width: `${ratings * 20}%`}} className="absolute z-10 h-full bg-black rounded-lg" />
        <span className="absolute w-full h-full bg-gray-200 z" />
      </div>
      <span>{ratings}</span>
    </div>
  </div>
} 

const Comment = ({ name, date, text, reply}) => {
  return <div className={`my-12 ${reply && 'ml-12'}`}>
    <div className="flex gap-4 my-4 items-center">
      <StaticImage src="https://wordsmith.org/words/images/avatar2_large.png" className="w-16 h-16 rounded-full" />
      <div className="">
        <p className="font-bold">{name}</p>
        <p className="text-gray-400">{date}</p>
      </div>
    </div>
      <div className="">{text}</div>
  </div>
}

const ReviewsRatings = ({data}) => {
  console.log("Review ", data)
  return (
    <div className="grid grid-cols-1 mt-10 gap-4 sm:grid-cols-3 mx-8 sm:mx-0">
      <div className="col-span-1 flex flex-col gap-4">
        <p className="flex text-3xl font-bold mb-5 items-center gap-4"><Star color="#ff395b" /> 4.68 · 22 Reviews</p>
        <RatingMeter text="Cleanliness" ratings={4.8} />
        <RatingMeter text="Accuracy" ratings={4.9} />
        <RatingMeter text="Communication" ratings={4.8} />
        <RatingMeter text="Location" ratings={4.9} />
        <RatingMeter text="Check-in" ratings={4.8} />
        <RatingMeter text="Value" ratings={4.5} />
      </div>
      <div className="col-span-2 lg:ml-12 sm:mx-auto mt-12 sm:mt-0">
        <div className="rounded-full border-2 bg-gray-100 flex p-4 gap-4 w-full">
          <Search />
          <input type="text" className="bg-gray-100 w-full"  placeholder="Search Reviews" />
        </div>
        <Comment name="Ramaswamy" avatar="" date="August 2021" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
        <Comment reply name="Response from Lokesh" avatar="" date="August 2021" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
        <Comment name="Ramaswamy" avatar="" date="August 2021" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
        <Comment name="Ramaswamy" avatar="" date="August 2021" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." />
      </div>
    </div>
  )
}

export default ReviewsRatings
