import React from "react"

const dummyData = {
  aboutHeading: 'About Headline',
  aboutDescription: "About Description: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the",
}

const About = ({data}) => {
  const {aboutHeading, aboutDescription} = {...dummyData, ...data.nodes[0]?.meta ?? {}};
  return (
    <div className="banner-wrap overflow-hidden mt-4 mx-8 sm:mx-12">
      <h2 className="my-8 text-3xl font-bold">{aboutHeading}</h2>
      <p className="text-lg font-light mb-8">{aboutDescription}</p>
    </div>
  )
}

export default About