import * as React from "react"
import Banner from "../components/Banner/Banner"

import Layout from "../components/layout"

import GridA from "../components/GridA/GridA"
import About from "../components/About/About"
import GridB from "../components/GridB/GridB"
import Amenities from "../components/Amenities/Amenities"
import ReviewsRatings from "../components/ReviewsRatings/ReviewsRatings"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { LoginBlock } from "../components/loginBlock"
import { Loading } from "../components/loading"
import { AuthEnabledContext } from "../AuthContext"

const Home = ({ data }) => (
  <Layout>
    <Seo title={"Airstays"} />
    <Banner data={data.banner} />
    <GridA data={data.locationFeatures} />
    <GridB data={data.showcase} extras={data.extras} />
    <About data={data.extras} />
    <Amenities data={data.amenities} />
    <ReviewsRatings data={data.reviews} />
  </Layout>
)

const PartialContent = ({ data, loginWithRedirect }) => {
  return (
    <Layout>
      <Seo title={"Airstays"} />
      <Banner data={data.banner} />
      <LoginBlock loginWithPopup={loginWithRedirect} />
    </Layout>
  )
}

const IndexPage = ({ data }) => {
  const isAuthEnabled = React.useContext(AuthEnabledContext);
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  if(!isAuthEnabled) {
    return  <Home data={data} />
  }

  if (isLoading) {
    return <Loading />
  }
  return (
    <>
      {!isAuthenticated && <PartialContent loginWithRedirect={loginWithRedirect} data={data} />}
      {isAuthenticated && (
        <Router>
          <Switch>
            <Route
              path="/"
              component={() => (
                <Home data={data} />
              )}
            />
          </Switch>
        </Router>
      )}
    </>
  )
}

export const query = graphql`
  {
    banner: allMasonContentCreative(
      filter: { meta: { contentType: { eq: "banner" } } }
      sort: { fields: updated_time, order: DESC }
      limit: 1
    ) {
      nodes {
        url
        meta {
          url
        }
      }
    }
    extras: allMasonContentCreative(
      filter: { meta: { contentType: { eq: "extras" } } }
      sort: { fields: updated_time, order: ASC }
    ) {
      nodes {
        meta {
          aboutHeading
          aboutDescription
          availabilityColor
        }
      }
    }
    showcase: allMasonContentCreative(
      filter: { meta: { contentType: { eq: "showcase" } } }
      sort: { fields: updated_time, order: ASC }
    ) {
      nodes {
        url
        meta {
          position
        }
      }
      distinct(field: meta___position)
    }
    amenities: allMasonContentCreative(
      filter: { meta: { contentType: { eq: "amenities" } } }
      sort: { fields: updated_time, order: ASC }
    ) {
      nodes {
        url
        meta {
          title
        }
      }
    }
    reviews: allMasonContentCreative(
      filter: { meta: { contentType: { eq: "reviews" } } }
      sort: { fields: meta___date, order: ASC }
    ) {
      nodes {
        url
        meta {
          name
          description
          date
        }
      }
      distinct(field: meta___position)
    }
    locationFeatures: allMasonContentCreative(
      filter: { meta: { contentType: { eq: "location_features" } } }
      sort: { fields: updated_time, order: ASC }
    ) {
      nodes {
        url
        meta {
          heading
          description
          position
        }
      }
      distinct(field: meta___position)
    }
  }
`

export default IndexPage
