import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Footer = () => {
  return (
    <div className="foot-wrap full-bleed">
      <hr />
      <div className="layouted">
        <div className="links flex flex-col md:flex-row px-4 justify-between py-8">
          <div className="link-grp text-center md:text-left">
            <Link to="/">Home</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Blog</Link>
          </div>
          <div className="link-grp text-center">
            <Link to="/">FAQ</Link>
            <Link to="/">Customer Care</Link>
            <Link to="/">Returns and Cancellations</Link>
          </div>
          <div className="link-grp text-center md:text-right">
            <Link to="/">Terms of use</Link>
            <Link to="/">Privacy policy</Link>
            <Link to="/">Shipping policy</Link>
          </div>
        </div>
      </div>
      <hr />
      <div className="layouted">
        <div className="copy flex flex-col md:flex-row justify-between items-center">
          <p>© 2021 Creative Sparks, Inc. All rights reserved.</p>
          <div className="flex items-center">
            Powered by &ensp;{" "}
            <StaticImage
              className="w-12"
              src="https://media.kubric.io/api/assetlib/af505cb6-c529-4851-a0dd-f6a62415cb5d.png"
              alt="mason logo"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
