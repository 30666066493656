import React from "react"

export const LoginBlock = ({ loginWithPopup }) => (
  <>
    <div className="login">
      <div className="login__block">
        <p>View the rest of this post with a free account</p>
        <button
          onClick={loginWithPopup}
          type="button"
          className="login__block-button"
        >
          Sign in with Google
        </button>
      </div>
    </div>
  </>
)
